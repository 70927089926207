import {useTranslation} from "react-i18next";

const Hero = () => {
    const [t] = useTranslation()
  return <section id="hero" className="hero section dark-background">
      <img src="/assets/img/hero-bg-2.jpg" alt="" className="hero-bg" />
      <div className="container">
          <div className="row gy-4 justify-content-between">
              <div
                  className="col-lg-4 order-lg-last hero-img"
                  data-aos="zoom-out"
                  data-aos-delay={100}
              >
                  <img
                      src="/assets/img/hero-img.png"
                      className="img-fluid animated"
                      alt=""
                  />
              </div>
              <div
                  className="col-lg-6  d-flex flex-column justify-content-center"
                  data-aos="fade-in"
              >
                  <h1>
                      {t("Le payment , la fidélité , la sécurité , simple et intuitive")}
                  </h1>
                  <p>{t("La premiere solution complete pour la gestion de la fidélite de vos clients")}</p>
                  <div className="d-flex">
                      <a href="#about" className="btn-get-started">
                          {/*t("Commencez")*/}
                          {t("Comming soon")}
                      </a>

                  </div>
              </div>
          </div>
      </div>
      <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
      >
          <defs>
              <path
                  id="wave-path"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
          </defs>
          <g className="wave1">
              <use xlinkHref="#wave-path" x={50} y={3} />
          </g>
          <g className="wave2">
              <use xlinkHref="#wave-path" x={50} y={0} />
          </g>
          <g className="wave3">
              <use xlinkHref="#wave-path" x={50} y={9} />
          </g>
      </svg>
  </section>

}
export default Hero
