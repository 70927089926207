import React from 'react';
import './App.css';
import Home from "./pages/home/Home";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./locales/en.json"
import fr from "./locales/fr.json"
import de from "./locales/de.json"
import it from "./locales/it.json"
import es from "./locales/es.json"
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import ScanRedirect from "./pages/scan/ScanRedirect";
import DocumentMeta from 'react-document-meta';
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('47d53b5a7b8d284016f7a76c196c63c8', {autocapture: {elementInteractions: true}});
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: en,
            fr: fr,
            es: es,
            it: it,
            de: de
        },
        lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });


const router = createBrowserRouter([
    {path: "/" , element: <Home />},
    {path: "/scan" , element: <ScanRedirect /> ,},
    {path: "/privacy-policy" , element: <PrivacyPolicy />}
])

function App() {
    const meta = {
        title: 'Filedis',
        description: 'The first complete solution for managing your customer loyalty',
        canonical: 'https://fieldis.com',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'fidelity,store,customers,growth,payment,food,bakery,chicha lounge,lounge'
            }
        }
    };
    return (
        <DocumentMeta {...meta}>
        <RouterProvider router={router} />
        </DocumentMeta>
    );
}

export default App;
